import React from 'react';
import { Button } from 'react-bootstrap';
import './LandingPage.css'

const LandingPage = () => {
    return (
        <div>
           <div className="logosection"><img className="logo" src="assets/images/logo.jpg" alt=""/></div>
           <div><img className="infoimage" src="assets/images/info.png" alt=""/></div>
           <div className="infodiv colorline1"></div>
           <div className="infodiv colorline2"></div>
           <div className="infodiv colorline3">Digital Agency</div>
           <div className="infodiv colorline4">CONTACT: <a href="tel:+27731000685">0731000685</a></div>
           <div className="infodiv colorline5">Email: <a href="mailto:rodney@adrodmedia.co.za">rodney@adrodmedia.co.za</a></div>
           <div className="infodiv colorline6">
               <a href="https://www.instagram.com/adrodmedia" target="blank"><i class="fab fa-instagram icons"></i></a>
               <a href="https://www.behance.net/adrodmedia" target="blank"><i class="fab fa-behance-square icons"></i></a>
               <a href="https://www.facebook.com/AdRodMedia" target="blank"><i class="fab fa-facebook-square icons"></i></a>
               <a href="https://wa.me/+27731000685/?text=I came across your website Link:https://images.pexels.com/photos/6641243/pexels-photo-6641243.jpeg" target="blank"><i class="fab fa-whatsapp icons"></i></a> 
               {/* <b>Ready to Talk ?</b>  */}
               <div className="mybtn" >

               {/* <Button  variant="danger">Start a project</Button> */}
               </div>
           </div>
        </div>
    );
};

export default LandingPage;