import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Button } from "react-bootstrap";

import Landing from './pages/LandingPage';

function App() {
  return (
    <Router>
      <div>
        {/* <Navcomp /> */}
        <Route exact path="/" component={Landing} />
        {/* <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/careers" component={Careers} />
        <Route path="/teaming" component={Teaming} />
        <Route path="/news" component={News} />
        <Route path="/contact" component={Contact} /> */}

        {/* <Footer /> */}

      </div>
    </Router>
  );
}

export default App;
